import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './App.css'; // Import the CSS file

function App() {
  const canvasRef = useRef(null);
  const [isAboutVisible, setIsAboutVisible] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const ripples = [];

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      ripples.push({ x: clientX, y: clientY, radius: 0, alpha: 1 });
    };

    const draw = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = 'rgba(0, 0, 0, 0.1)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ripples.forEach((ripple, index) => {
        ripple.radius += 1;
        ripple.alpha -= 0.02;
        if (ripple.alpha <= 0) {
          ripples.splice(index, 1);
        } else {
          ctx.beginPath();
          ctx.arc(ripple.x, ripple.y, ripple.radius, 0, 2 * Math.PI);
          ctx.strokeStyle = `rgba(111, 199, 186, ${ripple.alpha})`;
          ctx.stroke();
        }
      });
      requestAnimationFrame(draw);
    };

    window.addEventListener('resize', resizeCanvas);
    window.addEventListener('mousemove', handleMouseMove);
    resizeCanvas();
    draw();

    const aboutSection = document.getElementById('about-section');
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsAboutVisible(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (aboutSection) {
      observer.observe(aboutSection);
    }

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      window.removeEventListener('mousemove', handleMouseMove);
      if (aboutSection) {
        observer.unobserve(aboutSection);
      }
    };
  }, []);

  const scrollToAbout = () => {
    const aboutSection = document.getElementById('about-section');
    if (aboutSection) {
      aboutSection.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    } else {
      console.log('About section not found'); // Debug log
    }
  };

  return (
    <div className="app-background">
      <div className="background-wrapper">
        <canvas id="ripple-canvas" ref={canvasRef}></canvas>
        <nav className="navbar">
          <Link to="/">Home</Link>
          <Link to="#" onClick={scrollToAbout}>About</Link> {/* Updated Link */}
        </nav>
        <div className="intro-text">Silver</div>
        <div className="sub-text">One man's trash, is another man's <span>$Silver</span></div>
        
        {/* Social Media Links */}
        <div className="social-media-links">
          <a href="https://discord.gg/7kbbRRCDhp" target="_blank" rel="noopener noreferrer"><i className="fab fa-discord"></i></a>
          <a href="https://twitter.com/Silver_KRC20" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
          <a href="https://t.me/silverkrc20" target="_blank" rel="noopener noreferrer"><i className="fab fa-telegram-plane"></i></a>
        </div>
        
        <div className={`centered-button ${isAboutVisible ? 'hidden' : ''}`}>
          <button className="gradient-button" onClick={scrollToAbout}>Learn More</button>
        </div>

        
        <div className="content">
          <section id="about-section" className="about-section section section1">
            <h2>About $Silver</h2>
            <div className="card-container">
              <div className="card">
                <div className="card-icon">❤️</div>
                <div className="card-title">Community project
                </div>
                <div className="card-description">The silver website is a product of a community funded project, silver is for everyone!
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default App;